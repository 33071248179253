import React from "react"
import { Button } from "reactstrap"

const FrontPage = () => {
  

  return (
    <>
      <div className="masthead">
        <div className="container h-100">
          <div className="row">
            <div className="mb-5 text-center front-button">
              <div className="front-font">
                "The Highest Quality Paired with<br/> The Most Competitive Pricing"
              </div>
              
              <Button
                className="shadow-lg btn btn-outline-white mt-2 fs-4"
                id="hbutton"
                href="#packages"
                type="button"
              >
                See Packages
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FrontPage
