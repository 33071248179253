import React from "react"
import MobileService from "./mobileService"
import ValetService from "./valetService"
import DropoffService from "./dropoffService"

const Services = () => {
  return (
    <>
      <div>
        <div className="mb-3">
          <MobileService />
        </div>
        <div className="mb-3">
          <ValetService />
        </div>
        <div className="">
          <DropoffService />
        </div>
      </div>
    </>
  )
}

export default Services
