import * as React from "react"
const AboutUs = () => {
  return (
    <>
      <div className="about-padding container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <h1 className="custom-border hide-scroll text-center">
              About Us & What We Do
            </h1>
            <div className="para-margin mt-5 mb-5">
              <p className="fs-5 text-break">
                Listen, we’re not going to give you a glorified mission
                statement, instead, we’ll keep it as simple as possible and give
                you the honest truth. We are a team of young professionals who
                live and die by full customer satisfaction and quality. We
                always strive to make our clients as happy as possible. We do
                this by focusing on three important concepts.
                <br />
                <br /> Firstly, we closely work with you to find ways around
                your busy schedule and provide many convenient options for both
                appointment times and vehicle drop-off/pickup. This includes
                mobile services, shuttle/valet services, and flexible vehicle
                drop-off and pick-up systems.
                <br />
                <br /> Secondly, we always provide top-of-the-line quality of
                service that you simply will love. We bring years of experience,
                passion, and lots of energy to the industry. We won’t release
                your vehicle until you are 100% satisfied.
                <br />
                <br />
                Thirdly, we offer the most competitive pricing you’ll find
                around, and that’s a promise! We guarantee transparent pricing,
                so we ensure that we’re on the same page with you in terms of
                pricing before we even start the job.
                <br />
                <br /> Our great reviews on Google and Facebook demonstrate that
                our clients understand and appreciate the unparalleled quality,
                expertise, and full customer satisfaction Limestone Car
                Detailing brings to each and every detail. We are only a phone
                call, text, or an email away, so don’t hesitate to contact us
                now to book your appointment and get your car feeling brand new
                again!
              </p>
            </div>
          </div>
          <div className="about-pic col-lg-6"></div>
        </div>
      </div>
    </>
  )
}

export default AboutUs
