import React from "react"

const ValetService = () => {
  return (
    <div className="about-padding container-fluid ">
      <div className="row">
        <div className="col-lg-6 order-lg-1">
          <h1 className="custom-border-mobile hide-scroll text-center valet-h1-margin">
            Valet/Shuttle Service
          </h1>
          <div className="valet-p-spacing mt-5 mb-5">
            <p className="fs-5 text-break">
              We are also proud to offer a unique shuttle/valet service where we
              pick up you or your vehicle from wherever you desire, make it look
              and feel brand new, and deliver it right back to you in a timely
              manner!
              <br />
              <br /> Are you at work all day? No problem at all! We’ll pick up
              your vehicle from your workplace, make it look and feel brand new,
              and deliver it back to your workplace before you’re done work so
              you can drive your perfectly clean, shiny, and beautifully
              smelling car straight home!
              <br />
              <br /> Do you live in an apartment building and you don’t have a
              driveway with running water? No problem at all! We’ll come to pick
              up your vehicle, make it look and feel brand new, and deliver it
              back to you in a timely manner!
            </p>
          </div>
        </div>
        <div className="valet-service-pic col-lg-6 order-lg-2"></div>
      </div>
    </div>
  )
}

export default ValetService
