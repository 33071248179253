import React from "react"
import Pricing from "./vehicles"
import styled from "@emotion/styled"
const PricingSection = styled.section`
  background: #1e3f68;
  padding-top: 40px;
  margin-top: 70px;
  padding-bottom: 80px;
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: 991px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 400px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`
const PackageIndex = () => {
  return (
    <>
      <PricingSection>

        <Pricing />
      </PricingSection>
    </>
  )
}

export default PackageIndex
