import * as React from "react"
import { Helmet } from "react-helmet"
import styled from "@emotion/styled"

import NavbarIndex from "../components/Navbar"
import StatsCard from "../components/stats"
import ScrollArrow from "../components/scrollButton/scrollButton"
import FrontPage from "../components/frontPage"
import AboutUs from "../components/aboutUs"
import Footer from "../components/footer"
import Gallery from "../components/gallery/beforeAfter"
import AllGallery from "../components/gallery"
import Services from "../components/cServices"
import ContactUs from "../components/contactUs"
import CeramicIndex from "../components/CeramicCoating"
import PackageIndex from "../components/Package"
import CeramicPackage from "../components/CeramicCoating/package"
import GoogleReviews from "../components/Reviews"


const SectionTitles = styled.div`
  font-size: 18pt;
  color: #646363;
  font-family: system-ui;
  margin-bottom: 30px;

  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
`
const Index = () => {
  return (
    <>
      <Helmet>
        <title>Limestone Car Detailing</title>
      </Helmet>
      <header>
        <section>
          <NavbarIndex />
        </section>
        <section>
          <FrontPage />
        </section>
      </header>

      <main>
        <section
          id="aboutus"
          style={{ paddingTop: "180px", paddingBottom: "50px" }}
        >
          <AboutUs />
        </section>
        <section
          style={{
            padding: "20px",
            backgroundColor: "#88d5ff",
            marginBottom: "20px",
          }}
        >
          <StatsCard />
        </section>
        <section
          id="services"
          style={{ paddingTop: "120px", marginBottom: "10px" }}
        >
          <Services />
        </section>

        <section>
          <SectionTitles className="text-center text-uppercase">
            Reviews
          </SectionTitles>
          <div className="section-container">
            <div className="scroll-container">
              <GoogleReviews />
            </div>
          </div>
        </section>

        <section id="packages">
          <PackageIndex />
        </section>

        <section style={{ paddingTop: "0px" }}>
          <CeramicPackage />
        </section>

        <section
          id="detailings"
          style={{ marginTop: "100px", marginBottom: "10px" }}
        >
          <CeramicIndex />
        </section>
        <section>
          <ScrollArrow />
        </section>
        <section
          id="gallery"
          style={{
            minHeight: "500px",
            marginTop: "50px",
            marginBottom: "100px",
            position: "relative",
          }}
        >
          <Gallery />
        </section>

        <section
          id="contact"
          style={{
            minHeight: "1000px",
            marginTop: "90px",
          }}
        >
          <ContactUs />
        </section>
        <section>
          <AllGallery />
        </section>
      </main>
      <section style={{ marginTop: "50px", position: "relative" }}>
        <footer>
          <Footer />
        </footer>
      </section>
    </>
  )
}

export default Index
