import React from "react"

const MobileService = () => {
  return (
    <div className="about-padding container-fluid ">
      <div className="row d-flex">
        <div className="col-lg-6 order-lg-2">
          <h1 className="custom-border-mobile hide-scroll text-center">
            Mobile Service
          </h1>
          <div className="mobile-p-spacing mt-5 mb-5">
            <p className="fs-5 text-break">
              We want to make getting your car detailed as easy as possible, so
              we can come right to you! And we bring all the needed equipment to
              make your car look and feel brand new.
              <br />
              <br /> Do you work from home? No problem at all! We’ll completely
              take care of your vehicle right in your own driveway while you’re
              working, worry and stress-free!
              <br />
              <br />
              Do you have to stay home to look after the kids or pets? No
              Problem at all! We’ll completely take care of your vehicle right
              in your own driveway while you look after them, worry and
              stress-free!
              <br />
              <br /> Or maybe you just simply want to stay home and watch TV?
              And in that case… No Problem at all! We’ll completely take care of
              your vehicle right in your own driveway while you relax, worry,
              and stress-free!
            </p>
          </div>
        </div>
        <div className="mobile-service-pic col-lg-6 order-lg-1"></div>
      </div>
    </div>
  )
}

export default MobileService
