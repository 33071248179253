import React, { useEffect } from "react"
// import styled from "@emotion/styled"



const GoogleReviews = () => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://widgets.sociablekit.com/google-reviews/widget.js"
    script.async = true
    script.defer = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <>

      <div className="px-1">
        <div className="sk-ww-google-reviews" data-embed-id="25403957"></div>
      </div>
    </>
  )
}

export default GoogleReviews
