import React from "react"

const DropoffService = () => {
  return (
    <div className="about-padding container-fluid ">
      <div className="row">
        <div className="col-lg-6 order-lg-2">
          <h1 className="border-dropoff hide-scroll text-center dropoff-h1-margin">
            Flexible Vehicle Drop-off/Pickup
          </h1>
          <div className="mobile-p-spacing mt-5 mb-5">
            <p className="fs-5 text-break">
              We also offer flexible vehicle drop-off and pick-up times at our
              location at 1792 Bath Rd. Kingston. You can choose to drop off
              your vehicle 24/7 whenever you please and deposit the keys in our
              secure key drop-off box. We’ll take full care of your vehicle and
              you can come to pick it up whenever you please, or we can either
              deliver the car back to you once it's all done!
            </p>
          </div>
        </div>
        <div className="dropoff-service-pic col-lg-6 order-lg-1"></div>
      </div>
    </div>
  )
}

export default DropoffService
