import React, { useState, useEffect, useRef } from 'react';

const StatsCard = () => {
  const [number, setNumber] = useState(0);
  const cardRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // Start the number incrementing animation when component is in view
          const interval = setInterval(() => {
            setNumber(prevNumber => {
              const nextNumber = prevNumber + 1;
              return nextNumber <= 550 ? nextNumber : prevNumber; // Stop incrementing at 550
            });
          }, 1); // Increment the number every 2 milliseconds

          return () => clearInterval(interval); // Cleanup the interval on component unmount
        }
      },
      { threshold: 0.5 } // Trigger when at least 50% of the component is visible
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  return (
    <div className="container">
      <div className="row text-center">
        <div className="col-lg-4 stat-center">
          <h1 className="hide-scroll" style={{ color: "black" }}>
            Our Stats:
          </h1>
        </div>

        <div ref={cardRef} className="col-lg-4 col-sm-6">
          <div className="card-box bg-blue">
            <div className="inner">
              <h3 className="number">{number}+</h3>
              <p>Cars Detailed</p>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-sm-6">
          <div className="card-box bg-yellow">
            <div className="inner">
              <i className="bi bi-star-fill" />
              <i className="bi bi-star-fill" />
              <i className="bi bi-star-fill" />
              <i className="bi bi-star-fill" />
              <i className="bi bi-star-fill" />
              <p>Google Reviews</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsCard;
