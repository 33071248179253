import React from "react"
import { Button } from "reactstrap"
import Google from "../../images/google.png"
import { TawkContext } from "../../providers"
const ContactUs = () => {
  const { tawkMessenger } = React.useContext(TawkContext)
  return (
    <>
      <div className="container-xl">
        <div className="row">
          <div className="col-md-8 contact-padding">
            <h1 className="custom-border hide-scroll text-center mb-5 fw-bold">
              Contact Us
            </h1>
            <div className="row">
              <div className="fs-5 col-11 offset-1 ">
                Proudly serving Kingston and the surrounding area! Contact us
                now to set up your appointment and get your car looking and
                feeling brand new!
              </div>
            </div>
            <div className="row my-5">
              <div className="col-9 offset-2 col-sm-9 offset-sm-3 offset-lg-4 col-lg-8 col-md-9 offset-md-2">
                <div className="fs-6">
                  <p>
                    <a className="link" href="tel:+16138934492">
                      <i className="bi bi-telephone-plus-fill me-3" />
                      Call or Text: 613-893-4492
                    </a>
                  </p>
                </div>
                <div className="row fs-6">
                  <p>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="link"
                      href="mailto:limestonecardetailing@gmail.com"
                    >
                      <i className="bi bi-envelope me-3 " />
                      limestonecardetailing@gmail.com
                    </a>
                  </p>
                </div>
                <div className="row fs-6">
                  <p>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="link"
                      href="https://goo.gl/maps/5Gyf3GDaDe2Wxp1g6"
                    >
                      <i className="bi bi-car-front-fill me-3" />
                      1792 Bath Rd., Kingston, ON, K7M 4Y2
                    </a>
                  </p>
                </div>

                <div className="row fs-6">
                  <p>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="link"
                      href="https://www.google.com/maps/place/Limestone+Car+Detailing/@44.2380942,-76.5799452,17z/data=!4m8!3m7!1s0x4cd2adcb323daf3f:0x2689074440a1f4fc!8m2!3d44.2380942!4d-76.5777565!9m1!1b1!16s%2Fg%2F11pqym8md7"
                    >
                      <img
                        src={Google}
                        alt="google icon"
                        className="google-icon me-2"
                      />
                      <i className="bi bi-star-fill yellow-stars" />
                      <i className="bi bi-star-fill yellow-stars" />
                      <i className="bi bi-star-fill yellow-stars" />
                      <i className="bi bi-star-fill yellow-stars" />
                      <i className="bi bi-star-fill yellow-stars me-2" />
                      <div className="google-review">
                        Check Out Our Reviews!
                      </div>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <p className="custom-border text-center fs-3 mt-2 fw-bold">
                Hours of Service
              </p>
            </div>
            <div className="row text-center fs-3">
              <p>From 08:00 am to Sunset</p>
            </div>
            <div className="row my-2">
              <Button
                onClick={() => {
                  tawkMessenger.toggle()
                }}
                className="contact-button"
              >
                Contact Us
              </Button>
            </div>
            <div className="row text-center fs-5 mt-4 font-weight-500">
              <p>Stay Connected With Us!</p>
            </div>
            <div className="row text-center">
              <p>
                <a
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/limestonecardetailing"
                >
                  <i className="bi bi-facebook facebookIcon me-2 align-middle"></i>
                  www.facebook.com/limestonecardetailing
                </a>
              </p>
            </div>
            <div className="row text-center">
              <p>
                <a
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/limestonecardetailing"
                >
                  <i className="bi bi-instagram instaIcon me-2 align-middle" />
                  www.facebook.com/limestonecardetailing
                </a>
              </p>
            </div>
          </div>

          <div className="contact-pic col-md-4"></div>
        </div>
      </div>
    </>
  )
}

export default ContactUs
