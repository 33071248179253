import React from "react";
import styled from "@emotion/styled";

const PackageTitle = styled.div`
  font-size: 15pt;
  color: white;
  margin-top: 30px;
  margin-bottom: 50px;

  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

const Items = styled.div`
  color: white;
  font-size: 18px;

  
`;
const Logo = styled.i`
  margin-right: 10px;
  color: yellow;
`;
const AddOn = () => {
  return (
    <>
      <PackageTitle className="text-center">
        Add-ons
      </PackageTitle>

      <div className="container">
        <div className="row">
          <Items className="col-12">
            <Logo className="bi bi-bookmark-plus" />
            Rim coating ($150-$250)
          </Items>
          <Items className="col-12">
            <Logo className="bi bi-bookmark-plus" />
            Glass coating ($100-$150)
          </Items>
          <Items className="col-12">
            <Logo className="bi bi-bookmark-plus" />
            Interior coating for fabric & leather ($150-$250)
          </Items>
          <Items className="col-12">
            <Logo className="bi bi-bookmark-plus" />
            additional layers ($150)
          </Items>
 
        </div>
      </div>
    </>
  );
};

export default AddOn;
