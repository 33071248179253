import React from "react"
import Before1 from "../../images/IMG_5653.webp"
import After1 from "../../images/IMG_5798.webp"
import Before2 from "../../images/IMG_4995.webp"
import After2 from "../../images/front.webp"
import Before3 from "../../images/pic1.webp"
import After3 from "../../images/pic2.webp"
import Before4 from "../../images/pic3.webp"
import After4 from "../../images/pic4.webp"
import Before5 from "../../images/pic5.webp"
import After5 from "../../images/pic6.webp"
import Before6 from "../../images/pic7.webp"
import After6 from "../../images/pic8.webp"
import Before7 from "../../images/pic9.webp"
import After7 from "../../images/pic10.webp"
import Before8 from "../../images/pic11.webp"
import After8 from "../../images/pic12.webp"
import Before9 from "../../images/pic13.webp"
import After9 from "../../images/pic14.webp"
import Before10 from "../../images/pic15.webp"
import ReactImageGallery from "react-image-gallery"
// import styled from "@emotion/styled"
const images = (() => {
  return [
    Before1,
    After1,
    Before2,
    After2,
    Before3,
    After3,
    Before4,
    After4,
    Before5,
    After5,
    Before6,
    After6,
    Before7,
    After7,
    Before8,
    After8,
    Before9,
    After9,
    Before10,

  ].map(i => {
    return {
      original: i,
      thumbnail: i,
      thumbnailHeight: 100,
      originalHeight:450,
      
    }
  })
})()
// const SectionHeading = styled.div`
//   font-size: 35pt;
//   color: black;
//   text-align: center;
//   text-transform: uppercase;
//   @media screen and (max-width: 600px) {
//     font-size: 25pt;
//   }
// `
const AllGallery = () => {
  return (
    <>
  
    <div className="container-lg">
      <ReactImageGallery disableThumbnailScroll showIndex autoPlay lazyLoad items={images} />
    </div>
      
    </>
  )
}


export default AllGallery
