import React, { useState, useContext, useRef, useEffect } from "react"
import { TawkContext } from "../../providers"
import Logo from "../../images/Main-Logo.webp"
import {
  Button,
  Navbar,
  NavbarToggler,
  NavLink,
  Nav,
  NavItem,
  Collapse,
} from "reactstrap"

const NavbarIndex = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const { tawkMessenger } = useContext(TawkContext)

  let menuRef = useRef()

  useEffect(() => {
    let handler = e => {
      if (
        isOpen &&
        !isOpen &&
        menuRef &&
        !menuRef.current.nodeRef.current.contains(e.target)
      ) {
        setIsOpen(false)
        console.log(menuRef.current)
      }
    }

    document.addEventListener("mousedown", handler)

    return () => {
      document.removeEventListener("mousedown", handler)
    }
  })

  return (
    <>
      {/* <div className="top-link text-center mt-3">
        <div className="col-12 link-border fs-3">
          www.Limestonecardetailing.com
        </div>
      </div> */}
      <div
        style={{ backgroundColor: "#FFFFFF", height: "120px" }}
        className="row shadow fixed-top"
      >
        <div className="col-6 offset-3">
          <div className="d-flex justify-content-center">
            <a href="/">
              <img width={125} height={105} src={Logo} alt="Logo" />
            </a>
          </div>
        </div>
        <div className="col-3 align-self-center d-flex justify-content-end">
          <Button
            onClick={() => {
              tawkMessenger.toggle()
            }}
            className="book-button me-3"
          >
            Book Now
          </Button>
        </div>
      </div>
      <Navbar
        className="shadow navbar-light fixed-top d-lg-none"
        style={{ backgroundColor: "#FFFFFF" }}
        light
      >
        <div>
          <Button
            onClick={() => {
              tawkMessenger.toggle()
            }}
            className="book-button"
          >
            Book Now
          </Button>
        </div>
        <div className="main-logo">
          <div className="">
            <a href="/">
              <img width={125} height={105} src={Logo} alt="Logo" />
            </a>
          </div>
        </div>

        <NavbarToggler className="d-block d-lg-none" onClick={toggle} />
        <Collapse id="nav-toggler" navbar isOpen={isOpen} ref={menuRef}>
          <Nav className="text-center" navbar>
            <NavItem>
              <NavLink
                onClick={() => {
                  toggle()
                }}
                className="nav-item"
                href="#aboutus"
              >
                About Us
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                onClick={() => {
                  toggle()
                }}
                className="nav-item"
                href="#services"
              >
                Convenient Services
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                onClick={() => {
                  toggle()
                }}
                className="nav-item"
                href="#detailings"
              >
                Ceramic Coating
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                onClick={() => {
                  toggle()
                }}
                className="nav-item"
                href="#packages"
              >
                Packages
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                onClick={() => {
                  toggle()
                }}
                href="#contact"
                className="nav-item"
              >
                Contact Us
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      <div className="d-none d-lg-block under-navbar fixed-top shadow">
        <Nav className="d-flex flex-row d-flex justify-content-center" navbar>
          <NavItem>
            <NavLink className="nav-item mx-7" href="#aboutus">
              About Us
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink className="nav-item mx-7" href="#services">
              Convenient Services
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink className="nav-item mx-7" href="#detailings">
            Ceramic Coating
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink className="nav-item mx-7" href="#packages">
              Packages
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              onClick={() => {
                tawkMessenger.toggle()
              }}
              href="#contact"
              className="nav-item mx-7"
            >
              Contact Us
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    </>
  )
}

export default NavbarIndex
