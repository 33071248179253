import React from "react"
import styled from "@emotion/styled"
import { TawkContext } from "../../providers"
import Checkmark from "../../images/checkmark.png"
import AddOn from "./addOn"

const PackageTitle = styled.div`
  font-size: 14pt;
  color: white;

  margin-bottom: 50px;

  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
`
const SectionHeading = styled.div`
  font-size: 35pt;
  color: white;
  text-align: center;
  font-family: sans-serif;
  @media screen and (max-width: 600px) {
    font-size: 25pt;
  }
`
const SectionDescription = styled.div`
  font-size: 12pt;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  color: #059089;
  margin-bottom: 40px;
  margin-left: 23px;
  margin-right: 23px;
  padding-left: 50px;
  padding-right: 50px;
`

const SectionContainer = styled.section`
  background-color: #333;
  padding: 80px 20px;
  color: #fff;
`

const PackageWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  justify-content: space-around;
  align-items: center;
`
const AddonSection = styled.div`
  margin-top: 40px;
  text-align: left;
`
const PackageCard = styled.div`
 
  position: relative;
  background-color: #444;
  padding: 23px;
  min-height: 370px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
`
const GPackageCard = styled.div`
 
  position: relative;
  background-color: #444;
  padding: 23px;
  min-height: 370px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  border: 0.5px solid yellow;
`
const BCardTitle = styled.div`
  font-size: 30px;
  color: #cd7f32;
  text-align: left;
`
const SCardTitle = styled.div`
  font-size: 30px;
  color: #c0c0c0;
  text-align: left;
`
const GCardTitle = styled.div`
  font-size: 30px;
  color: #ffd700;
  text-align: left;
`
const UCardTitle = styled.div`
  font-size: 30px;
  color: lightblue;
  text-align: left;
`

const CardPrice = styled.div`
  font-size: 25px;
  color: #059089;
`
const CardPricespan = styled.span`
  font-size: 15px;
  color: lightgray;
`
const CardPriceL = styled.div`
  font-size: 11pt;
`

const BookButton = styled.button`
  width: 75%;
  height: 35px;
  border-radius: 7px;
  background: #036762;
  color: black;
  font-weight: 500;
  border: 0;
  font-size: 12pt;
  transition: 0.2s;
  &:hover {
    background: #014d49;
    transition: 0.1s;
  }
`
const PricingPopular = styled.button`
  position: absolute;
  width: fit-content;
  padding: 3px 12px 3px 12px;
  border-radius: 25px 0px 25px 25px;
  color: black;
  font-weight: 500;
  border: 0;
  right: 12px; 
  top: 12px; 

  background: gold;
  &:hover {
    cursor: default;
  }
`;
const Check = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 3px;
`
const CeramicPackage = () => {

  const { tawkMessenger } = React.useContext(TawkContext)
  return (
    <>
      <SectionContainer>
        <PackageTitle
          id="CeramicPackages"
          className="text-center text-uppercase"
        >
          Ceramic Coating
        </PackageTitle>
        <SectionHeading>OUR PACKAGES</SectionHeading>
        <SectionDescription>
          Our process is: wash, iron, clay bar, 1-2 stage paint correction, and
          1 layer of coating on body.
        </SectionDescription>
        <PackageWrapper className="container pricing-md-max">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12 mb-sm-3 mb-4">
              <PackageCard>
                {/* Add content for Bronze package */}
                <BCardTitle>Bronze</BCardTitle>
                <br />
                <CardPrice>
                  $599<CardPricespan> /Starting</CardPricespan>
                </CardPrice>
                <hr />
                <br />
                <div className="me-2">
                  <Check src={Checkmark} />1 year Coating
                </div>
                <br />
                <br />
                <div className="mb-4">
                  <BookButton onClick={() => tawkMessenger.toggle()}>
                    Book Now
                  </BookButton>
                </div>

                <CardPriceL>Larger vehicles $699+</CardPriceL>
              </PackageCard>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mb-sm-3 mb-4">
              <PackageCard>
                {/* Add content for Silver package */}
                <SCardTitle>Silver</SCardTitle>
                <br />
                <CardPrice>
                  $799<CardPricespan> /Starting</CardPricespan>
                </CardPrice>
                <hr />
                <br />
                <div className="me-2">
                  <Check src={Checkmark} />3 year Coating
                </div>

                <br />
                <br />
                <div className="mb-4">
                  <BookButton onClick={() => tawkMessenger.toggle()}>
                    Book Now
                  </BookButton>
                </div>
                <CardPriceL>Larger vehicles $999+</CardPriceL>
              </PackageCard>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mb-sm-3 mb-4">
              <GPackageCard>
                {/* Add content for Gold package */}
                <div className="text-end">
                <PricingPopular className="col">Popular</PricingPopular>
              </div>
                <GCardTitle>Gold</GCardTitle>
                <br />
                <CardPrice>
                  $1299<CardPricespan> /Starting</CardPricespan>
                </CardPrice>
                <hr />
                <br />
                <div className="me-2">
                  <Check src={Checkmark} />5 year Coating
                </div>

                <br />
                <br />
                <div className="mb-4">
                  <BookButton onClick={() => tawkMessenger.toggle()}>
                    Book Now
                  </BookButton>
                </div>
                <CardPriceL>Larger vehicles $1599+</CardPriceL>
              </GPackageCard>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mb-sm-3 mb-4">
              <PackageCard>
                {/* Add content for Ultimate package */}
                <UCardTitle>Ultimate</UCardTitle>
                <br />
                <CardPrice>
                  $1599<CardPricespan> /Starting</CardPricespan>
                </CardPrice>
                <hr />
                <br />
                <div className="me-2">
                  <Check src={Checkmark} />7 year Coating
                </div>

                <br />
                <br />
                <div className="mb-4">
                  <BookButton onClick={() => tawkMessenger.toggle()}>
                    Book Now
                  </BookButton>
                </div>
                <CardPriceL>Larger vehicles $1899+</CardPriceL>
              </PackageCard>
            </div>
          </div>
        </PackageWrapper>
        <AddonSection>
          <AddOn />
        </AddonSection>
      </SectionContainer>
    </>
  )
}

export default CeramicPackage
