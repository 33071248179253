import React from "react"

const Footer = () => {
  return (
    <>
      <div className="">
        <footer
          className="text-lg-start text-dark"
        >

          <div
            className="text-center p-3 align-middle"
            style={{ backgroundColor: "#24262b" }}
          >
            
            <div style={{ color: "white", fontSize: 15 }}>
            Limestone Car Detailing © {(new Date()).getFullYear()} &nbsp; &nbsp; Built by - <a target="_blank" rel="noreferrer" className="link" href="https://hussainalnakhli.com/">Hussain Alnakhli</a>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default Footer
