import React from "react"

const CeramicIndex = () => {
  return (
    <>
      <h1 className="detailing-title text-center">Ceramic Coating</h1>
      <div className="about-padding container-fluid">
        <div className="row">
          <div className="col-lg-6 order-lg-1">
            <h1 className="custom-border-mobile hide-scroll text-center">
              What is Ceramic Coating?
            </h1>
            <div className="detailing-p-spacing mt-5 mb-5">
              <p className="fs-5 text-break">
                Ceramic coating is a liquid polymer based product that is
                applied by hand to the exterior of a vehicle. Ceramic coating
                chemically bonds with your vehicle's paint creating a layer of
                protection that is thick similar to your paint's clear coat.
                Often the term 9H or 10H hardness is linked with ceramic
                coating, referring to the pencil scale of hardness test.
                <br /> <br />
                Ceramic coating is a paint protection that creates a
                semi-permanent bond that does not wash away or needs to be
                applied every month such as a wax.
                <br /> <br />
                Ceramic coating replaces wax as it is the new way of protection
                for your vehicle. It is also known as nano-coating,
                nano-technology, or glass coating.
                
              </p>
            </div>
          </div>
          <div className="whatpic col-lg-6 order-lg-2"></div>
        </div>
      </div>
      <br /> <br />
      {/* Why Section */}
      <div className="about-padding container-fluid">
        <div className="row">
          <div className="col-lg-6 order-lg-2">
            <h1 className="custom-border-mobile hide-scroll text-center">
              Why Ceramic Coat your Vehicle?
            </h1>
            <div className="detailing-p-spacing mt-5 mb-5">
              <p className="fs-5 text-break">
                Protect your investment: Ceramic coating protect's your vehicle
                from everyday wear and tear, paint fading, rusting, UV
                protection, minor scratches, swirl marks, bird dropping, mud,
                dirt, & acid rain.
                <br /> <br />
                Gloss properties: Ceramic coating provides a longer lasting
                sheen and protection to your vehicle and essentially eliminates
                from continuous waxing.
                <br /> <br />
                Low maintance: Less car washes with ceramic coated vehicles.
                Provides hydrophonic properties that repell water and make
                maintaining your vehicle much easier as dirt is easily removed.
                Save's you time and money from constant car washes.
                <br />
              </p>
            </div>
          </div>
          <div className="whypic col-lg-6 order-lg-1"></div>
        </div>
      </div>
    </>
  )
}

export default CeramicIndex
